<template>
  <div>
    <v-app-bar app color="primary" dark flat class="mx-auto" height="80px" fixed>
      <v-row no-gutters class="ma-0 d-flex align-center justify-center" style="width: 100%;">
        <v-col cols="2">
          <v-app-bar-nav-icon v-if="!isSetNewPasswordScreen" @click="drawer = !drawer">
          </v-app-bar-nav-icon>
        </v-col>
        <v-col cols="8">
          <v-img :src="require('@/assets/Unija_logo.svg')"
                 max-height="28px"
                 contain
                 @click="isSetNewPasswordScreen ? logout() : goToHome()"/>
        </v-col>
        <v-col cols="2" class="d-flex justify-end">
          <v-menu
            bottom
            close-on-click
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-badge
                :content="unreadNotifications"
                :value="unreadNotifications"
                color="info"
                overlap
              >
                <v-btn v-if="!isSetNewPasswordScreen"
                       v-bind="attrs"
                       v-on="on"
                       icon tile>
                  <v-icon color="white">
                    $bell
                  </v-icon>
                </v-btn>
              </v-badge>
            </template>
            <v-list max-height="300" max-width="300" class="pa-0">
              <template v-for="(item) in notifications">
                <v-list-item
                  v-if="item.unread"
                  :key="item.timestamp"
                  link
                  three-line
                  style="min-height: 0px !important; max-height: 120px !important;"
                  @click="openNotificationMessage(item)"
                >
                  <v-list-item-content class="align-self-baseline">
                    <v-list-item-title class="notification-title">
                      <v-icon color="grey" small>
                        $message
                      </v-icon>
                      {{ item.verb }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-list-item
                v-if="notifications.filter(notification => notification.unread).length === 0"
              >
                <v-list-item-content class="pa-0 align-self-center">
                  <v-list-item-title class="subtitle-2 text-center">
                    No new messages
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider />
              <v-list-item link @click="goToMessages">
                <v-list-item-content class="pa-0 align-self-center">
                  <v-list-item-title class="notification-title">
                    All my messages ...
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      height="100%"
    >
      <v-list
        nav
        dense
        class="d-flex flex-column"
      >
        <v-list-item-group
          color="primary"
        >
          <v-list-item
            v-for="element in drawerElements"
            :key="element.name"
            link
            :to="element.route"
          >
            <v-list-item-icon>
              <v-icon>{{ element.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ element.name }}</v-list-item-title>
          </v-list-item>
          <v-spacer />
        </v-list-item-group>
      </v-list>
      <v-list-item
        style="position: fixed; bottom: 0; left: 0;"
        @click="logout"
      >
        <v-list-item-icon>
          <v-icon>$logout</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Log out</v-list-item-title>
      </v-list-item>
    </v-navigation-drawer>
    <v-main class="d-flex" style="height: 100vh;">
      <router-view />
    </v-main>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import store from "../store";

export default {
  name: "DefaultLayout",
  data() {
    return {
      drawer: false,
    }
  },
  computed: {
    ...mapState('user', ['notifications']),
    unreadNotifications() {
      return this.notifications.filter((notif) => notif.unread).length;
    },
    pageName: {
      get() {
        return this.$route.name;
      }
    },
    isSetNewPasswordScreen: {
      get() {
        if (this.$route.name === 'SetNewPassword') {
          return true
        }
        return false;
      },
    },
    drawerElements: {
      get() {
        if (store.getters['user/userGroups'].includes("AdvancedBI")) {
          return [
            { name: 'Capture invoice', icon: '$camera', route: '/' },
            { name: 'History', icon: '$list', route: '/history' },
            { name: 'BI', icon: '$bi', route: '/bi' }
          ]
        }
        if (store.getters['user/userGroups'].includes("Advanced")) {
          return [
            { name: 'Capture invoice', icon: '$camera', route: '/' },
            { name: 'History', icon: '$list', route: '/history' },
          ]
        }
        return [
          { name: 'Capture invoice', icon: '$camera', route: '/' },
        ];
      }
    }
  },
  methods: {
    async logout() {
      await this.$store.dispatch('user/logout');
      await this.$router.push('/login');
    },
    async goToHome() {
      if (this.$route.path !== '/') await this.$router.push('/');
    },
    async goToMessages() {
      if (this.$route.path !== '/messages') await this.$router.push('/messages');
    },
    openNotificationMessage(notification) {
      this.$router.push({ path: '/messages', query: { message_id: notification.id } });
    },
  }
};
</script>

<style scoped>
.notification-title {
  color: #7a003c;
  white-space: normal !important;
  font-size: 0.8rem !important;
  font-weight: 600;
}
.read {
  font-weight: 400;
}
.notification-subtitle {
  -webkit-line-clamp: 4;
  font-size: 10px !important;
  font-weight: 500;
}
.v_toolbar__content {
  padding: 8px !important;
}
</style>
